"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BoardGroupHeader = BoardGroupHeader;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var animation_1 = require("@shared/components/animation");
var styles_1 = require("@shared/styles");
var hooks_1 = require("@shared/util/hooks");
var GroupHeaderStyles_1 = require("./GroupHeaderStyles");
var GroupHeaderText_1 = require("./GroupHeaderText");
function BoardGroupHeader(_a) {
    var group = _a.group, isCollapsed = _a.isCollapsed, setIsCollapsed = _a.setIsCollapsed, count = _a.count, style = _a.style;
    var _b = (0, hooks_1.useBoolean)(false), isCollapsedTextVisible = _b[0], showCollapsedText = _b[1], hideCollapsedText = _b[2];
    var groupData = Array.from(group.values())[0];
    var filter = groupData === null || groupData === void 0 ? void 0 : groupData.filter;
    (0, react_1.useEffect)(function () {
        // temporarily hide text when collapsible state changes, then reshow at the
        // new position
        hideCollapsedText();
        var timer = setTimeout(function () {
            if (isCollapsed) {
                showCollapsedText();
            }
        }, 200);
        return function () { return clearTimeout(timer); };
    }, [showCollapsedText, hideCollapsedText, isCollapsed]);
    if ((0, lodash_1.isNil)(filter)) {
        return null;
    }
    return (react_1.default.createElement(react_native_1.TouchableOpacity, { onPress: function () { return setIsCollapsed(function (prev) { return !prev; }); }, testID: "card-group-header", style: [GroupHeaderStyles_1.Styles.header, style] },
        react_1.default.createElement(react_native_1.View, { style: GroupHeaderStyles_1.Styles.boardHeaderContent },
            react_1.default.createElement(react_native_1.View, { style: GroupHeaderStyles_1.Styles.collapseArrow },
                react_1.default.createElement(animation_1.MBAnimation, { duration: 200, easing: react_native_1.Easing.inOut(react_native_1.Easing.quad), disabled: isCollapsed, rotate: { from: '0deg', to: '-90deg' } },
                    react_1.default.createElement(components_1.MBIconV2, { color: styles_1.Colors.v2.purple, name: "arrow-point-down-heavy", size: (0, components_1.grid)(2) }))),
            isCollapsed && (react_1.default.createElement(react_native_1.View, { style: [
                    GroupHeaderStyles_1.Styles.boardHeaderText,
                    GroupHeaderStyles_1.Styles.boardHeaderTextCollapsed,
                    isCollapsedTextVisible && GroupHeaderStyles_1.Styles.boardHeaderTextVisible,
                ] },
                react_1.default.createElement(GroupHeaderText_1.GroupHeaderText, { group: group, count: count }))),
            !isCollapsed && (react_1.default.createElement(react_native_1.View, { style: [GroupHeaderStyles_1.Styles.boardHeaderText, GroupHeaderStyles_1.Styles.boardHeaderTextVisible] },
                react_1.default.createElement(GroupHeaderText_1.GroupHeaderText, { group: group, count: count }))))));
}
