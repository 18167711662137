"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupContainer = GroupContainer;
var core_1 = require("@dnd-kit/core");
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var use_debounce_1 = require("use-debounce");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var convertViewStyleToCss_1 = require("@shared/util/convertViewStyleToCss");
var DraggableContext_1 = require("../../listView/components/DraggableContext");
var useGroupCollapse_1 = require("../hooks/useGroupCollapse");
var BoardGroupHeader_1 = require("./BoardGroupHeader");
var GroupHeader_1 = require("./GroupHeader");
function GroupContainer(props) {
    var draggable = (0, DraggableContext_1.useDraggableContext)().draggable;
    var group = props.group, groupId = props.groupId, viewTemplate = props.viewTemplate, totalCardCount = props.totalCardCount, collapseIfEmpty = props.collapseIfEmpty;
    var _a = (0, useGroupCollapse_1.useGroupCollapse)({
        group: group,
        viewTemplate: viewTemplate,
        totalCardCount: totalCardCount,
        collapseIfEmpty: collapseIfEmpty,
    }), isCollapsed = _a[0], setIsCollapsed = _a[1];
    if (draggable && !(0, lodash_1.isNil)(groupId)) {
        return react_1.default.createElement(DroppableGroupContainer, __assign({}, props, { isCollapsed: isCollapsed, setIsCollapsed: setIsCollapsed, groupId: groupId }));
    }
    else {
        return react_1.default.createElement(NonDroppableGroupContainer, __assign({}, props, { isCollapsed: isCollapsed, setIsCollapsed: setIsCollapsed }));
    }
}
function DroppableGroupContainer(props) {
    var groupId = props.groupId;
    var activeContainer = (0, DraggableContext_1.useDraggableContext)().activeContainer;
    var isActiveContainer = activeContainer === groupId;
    var isCollapsed = useAutoExpand({
        isCollapsed: props.isCollapsed,
        activeContainer: activeContainer,
        isActiveContainer: isActiveContainer,
    });
    var setNodeRef = (0, core_1.useDroppable)({
        id: groupId,
        data: {
            type: 'group',
            groupId: groupId,
        },
    }).setNodeRef;
    var containerStyle = getGroupContainerStyle(__assign(__assign({}, props), { isCollapsed: isCollapsed }));
    var style = __assign(__assign({}, (0, convertViewStyleToCss_1.convertViewStyleToCss)(containerStyle)), { borderRadius: (0, components_1.grid)(1), borderBottomLeftRadius: 0, borderBottomRightRadius: 0, backgroundColor: isActiveContainer ? styles_1.Colors.v2.gray05 : styles_1.Colors.v2.transparent, boxShadow: isActiveContainer ? "inset 0 0 0 1px ".concat(styles_1.Colors.v2.gray30) : 'none' });
    return (react_1.default.createElement("div", { ref: setNodeRef, style: style },
        react_1.default.createElement(GroupContent, __assign({}, props, { isCollapsed: isCollapsed }))));
}
function NonDroppableGroupContainer(_a) {
    var props = __rest(_a, []);
    var style = getGroupContainerStyle(props);
    return (react_1.default.createElement(react_native_1.View, { style: style },
        react_1.default.createElement(GroupContent, __assign({}, props))));
}
function getGroupContainerStyle(props) {
    var isCollapsed = props.isCollapsed, wrapperStyles = props.wrapperStyles, totalCardCount = props.totalCardCount;
    var isGroupEmpty = totalCardCount === 0 || isCollapsed;
    var style = [
        { display: 'flex', flexDirection: 'column' },
        wrapperStyles === null || wrapperStyles === void 0 ? void 0 : wrapperStyles.container,
        isCollapsed && (wrapperStyles === null || wrapperStyles === void 0 ? void 0 : wrapperStyles.isCollapsed),
        isGroupEmpty && (wrapperStyles === null || wrapperStyles === void 0 ? void 0 : wrapperStyles.isEmpty),
    ];
    return style;
}
function GroupContent(_a) {
    var viewTemplate = _a.viewTemplate, totalCardCount = _a.totalCardCount, group = _a.group, isCollapsed = _a.isCollapsed, setIsCollapsed = _a.setIsCollapsed, _b = _a.isStickyTop, isStickyTop = _b === void 0 ? false : _b, _c = _a.isStickyLeft, isStickyLeft = _c === void 0 ? false : _c, _d = _a.showBackground, showBackground = _d === void 0 ? false : _d, children = _a.children, wrapperStyles = _a.wrapperStyles;
    var delayedIsCollapsed = (0, use_debounce_1.useDebounce)(isCollapsed, 100)[0];
    var isBoard = viewTemplate.subtype === 'BOARD';
    var showChildren = !isCollapsed || !delayedIsCollapsed;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        isBoard && (react_1.default.createElement(BoardGroupHeader_1.BoardGroupHeader, { group: group, isCollapsed: isCollapsed, setIsCollapsed: setIsCollapsed, count: totalCardCount,
            style: wrapperStyles === null || wrapperStyles === void 0 ? void 0 : wrapperStyles.header })),
        !isBoard && (react_1.default.createElement(GroupHeader_1.GroupHeader, { group: group, isCollapsed: isCollapsed, setIsCollapsed: setIsCollapsed, count: totalCardCount, isStickyTop: isStickyTop, isStickyLeft: isStickyLeft, showBackground: showBackground, style: wrapperStyles === null || wrapperStyles === void 0 ? void 0 : wrapperStyles.header,
            contentStyle: wrapperStyles === null || wrapperStyles === void 0 ? void 0 : wrapperStyles.headerContent })),
        showChildren && children));
}
// hook to manage auto-expand/collapse during drag operations
var AUTO_EXPAND_TIMEOUT_MS = 1000;
var AUTO_COLLAPSE_TIMEOUT_MS = 2000;
function useAutoExpand(_a) {
    var isCollapsed = _a.isCollapsed, activeContainer = _a.activeContainer, isActiveContainer = _a.isActiveContainer;
    var _b = (0, react_1.useState)(false), isExpanded = _b[0], setIsExpanded = _b[1];
    (0, react_1.useEffect)(function () {
        // when the container is active, expand it after a delay
        var timer = setTimeout(function () {
            if (isActiveContainer) {
                setIsExpanded(true);
            }
        }, AUTO_EXPAND_TIMEOUT_MS);
        return function () { return clearTimeout(timer); };
    }, [isActiveContainer]);
    (0, react_1.useEffect)(function () {
        // after a drag operation completes, collapse the container if it was collapsed before
        var timer = setTimeout(function () {
            if ((0, lodash_1.isNil)(activeContainer)) {
                setIsExpanded(false);
            }
        }, AUTO_COLLAPSE_TIMEOUT_MS);
        return function () { return clearTimeout(timer); };
    }, [activeContainer]);
    return isCollapsed && !isExpanded;
}
