"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagEditField = TagEditField;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var createTagRow_1 = require("@shared/components/blocks/lib/tags/createTagRow");
var tag_1 = require("@shared/components/blocks/lib/tags/tag");
var modal_1 = require("@shared/components/multiSelect/modal");
var Pill_1 = require("@shared/components/pills/Pill");
var TagField_1 = require("@shared/data/entity/fields/TagField");
var i18n_1 = require("@shared/util/i18n");
var EditFieldContainer_1 = require("../fields/EditFieldContainer");
var useAddTag_1 = require("./useAddTag");
function TagEditField(_a) {
    var _this = this;
    var _b;
    var field = _a.field, component = _a.component, updateField = _a.updateField, workflowTemplateId = _a.workflowTemplateId;
    var addTag = (0, useAddTag_1.useAddTag)(workflowTemplateId, component.id).addTag;
    var _c = (0, react_1.useState)([]), recentlyCreatedTagOptions = _c[0], setRecentlyCreatedTagOptions = _c[1];
    var componentOptions = (0, react_1.useMemo)(function () {
        var _a;
        return (0, lodash_1.uniqBy)(__spreadArray(__spreadArray([], recentlyCreatedTagOptions, true), ((_a = component.options) !== null && _a !== void 0 ? _a : []), true), function (t) { return t.value; });
    }, [component.options, recentlyCreatedTagOptions]);
    var options = (0, react_1.useMemo)(function () { var _a; return (_a = componentOptions.filter(function (option) { return !option.archived; })) !== null && _a !== void 0 ? _a : []; }, [componentOptions]);
    var archived = (0, react_1.useMemo)(function () { var _a; return (_a = componentOptions.filter(function (option) { return !!option.archived; })) !== null && _a !== void 0 ? _a : []; }, [componentOptions]);
    var optionValues = (0, react_1.useMemo)(function () { return options.map(function (option) { return option.value; }); }, [options]);
    var archivedValues = (0, react_1.useMemo)(function () { return archived.map(function (option) { return option.value; }); }, [archived]);
    var labelExtractor = (0, react_1.useCallback)(function (value) { var _a, _b; return (_b = (_a = componentOptions.find(function (option) { return option.value === value; })) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : ''; }, [componentOptions]);
    var selectedTags = (0, react_1.useMemo)(function () {
        var _a;
        var selected = (_a = field === null || field === void 0 ? void 0 : field.jsonBlob) !== null && _a !== void 0 ? _a : [];
        return selected.map(function (id) { return componentOptions.find(function (item) { return item.value === id; }); });
    }, [field === null || field === void 0 ? void 0 : field.jsonBlob, componentOptions]);
    var onChange = (0, react_1.useCallback)(function (selection) {
        updateField(component.id, new TagField_1.TagField(selection, component.id, options));
    }, [component.id, options, updateField]);
    var renderItem = (0, react_1.useCallback)(function (value) {
        var option = componentOptions.find(function (item) { return item.value === value; });
        return react_1.default.createElement(SelectModalItem, { option: option });
    }, [componentOptions]);
    var createAndSelectNew = (0, react_1.useCallback)(function (value) { return __awaiter(_this, void 0, void 0, function () {
        var result, newSelectedTags, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, addTag(value)];
                case 1:
                    result = _b.sent();
                    if (!(0, lodash_1.isNil)(result)) {
                        newSelectedTags = component.multiselect
                            ? __spreadArray(__spreadArray([], selectedTags.map(function (tag) { return tag.value; }), true), [result.value], false) : [result.value];
                        setRecentlyCreatedTagOptions(__spreadArray(__spreadArray([], recentlyCreatedTagOptions, true), [result], false));
                        updateField(component.id, new TagField_1.TagField(newSelectedTags, component.id, __spreadArray(__spreadArray([], options, true), [result], false)));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    _a = _b.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [
        addTag,
        component.id,
        component.multiselect,
        options,
        recentlyCreatedTagOptions,
        selectedTags,
        updateField,
    ]);
    var match = (0, react_1.useCallback)(function (value, query, exact) {
        var label = labelExtractor(value);
        if ((0, lodash_1.isNil)(label)) {
            return false;
        }
        if (exact) {
            return label.toLowerCase() === (query === null || query === void 0 ? void 0 : query.toLowerCase());
        }
        return label.toLowerCase().includes(query === null || query === void 0 ? void 0 : query.toLowerCase());
    }, [labelExtractor]);
    var renderCreateNew = (0, react_1.useCallback)(function (query, creating) {
        if (creating) {
            return (react_1.default.createElement(components_1.MBView, { style: { height: (0, components_1.grid)(8) }, center: true },
                react_1.default.createElement(components_1.MBLoadingIndicator, null)));
        }
        return react_1.default.createElement(createTagRow_1.MBCreateTagRow, { query: query, numberOfOptions: options.length });
    }, [options.length]);
    var onCreate = component.addAdditionalValues ? createAndSelectNew : undefined;
    var onClear = (0, react_1.useCallback)(function () {
        onChange([]);
    }, [onChange]);
    var hasTags = selectedTags.length > 0;
    var isReadOnly = !!component.readonly;
    if (isReadOnly) {
        return react_1.default.createElement(TagFieldSummary, { component: component, selectedTags: selectedTags, hasTags: hasTags });
    }
    return (react_1.default.createElement(modal_1.MBMultiSelectModal, { label: (_b = component.label) !== null && _b !== void 0 ? _b : '', onChange: onChange, onCreate: onCreate, selectAll: true, selectAllLabel: (0, i18n_1.i18n)('card.blocks.tagsInput.selectAllLabel'), renderItem: renderItem, values: optionValues, selectedValues: selectedTags === null || selectedTags === void 0 ? void 0 : selectedTags.map(function (t) { return t.value; }), labelExtractor: labelExtractor, match: match, renderCreateNew: renderCreateNew, archivedValues: archivedValues, itemLabel: (0, i18n_1.i18n)('card.blocks.tagsInput.itemLabel'), itemLabelPlural: (0, i18n_1.i18n)('card.blocks.tagsInput.itemLabelPlural'), errorLabel: (0, i18n_1.i18n)('errors.tag.enterName'), itemsEditable: false, max: component.multiselect ? undefined : 1 },
        react_1.default.createElement(TagFieldSummary, { component: component, selectedTags: selectedTags, hasTags: hasTags, onClear: onClear })));
}
function TagFieldSummary(_a) {
    var component = _a.component, selectedTags = _a.selectedTags, onClear = _a.onClear, hasTags = _a.hasTags;
    return (react_1.default.createElement(EditFieldContainer_1.EditFieldContainer, { component: component, showLabel: hasTags, showPlaceholder: !hasTags, onClear: onClear }, hasTags && (react_1.default.createElement(components_1.MBView, { style: styles.tagList }, selectedTags.map(function (tag) { return (react_1.default.createElement(tag_1.Tag, { key: tag.value, label: tag.label, color: tag.backgroundColor, archived: !!tag.archived, style: styles.pill })); })))));
}
function SelectModalItem(_a) {
    var option = _a.option;
    if ((0, lodash_1.isNil)(option)) {
        return null;
    }
    return (react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, flex: true },
        react_1.default.createElement(Pill_1.Pill, { key: option === null || option === void 0 ? void 0 : option.value, backgroundColor: option === null || option === void 0 ? void 0 : option.backgroundColor, round: true, style: styles.pill }, option === null || option === void 0 ? void 0 : option.label)));
}
var styles = react_native_1.StyleSheet.create({
    tagList: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingVertical: (0, components_1.grid)(0),
        // cheat rounded tags left a bit (optical alignment)
        marginLeft: -(0, components_1.grid)(1),
    },
    pill: {
        marginLeft: (0, components_1.grid)(0.5),
    },
});
