"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DraggableContextProvider = DraggableContextProvider;
var client_1 = require("@apollo/client");
var core_1 = require("@dnd-kit/core");
var sortable_1 = require("@dnd-kit/sortable");
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var queries_1 = require("@shared/api/queries");
var components_1 = require("@shared/components");
var useUpdateWorkflowEntity_1 = require("@shared/scenes/entity/edit/hooks/useUpdateWorkflowEntity");
var Card_1 = require("./Card");
var DraggableContext_1 = require("./DraggableContext");
// TODO remove this when sort mutation is ready
var SORT_ENABLED = false;
var dndContextConfig = {
    autoScroll: {
        activator: core_1.AutoScrollActivator.DraggableRect,
        acceleration: 1,
        layoutShiftCompensation: true,
    },
    collisionDetection: core_1.pointerWithin,
    measuring: {
        droppable: {
            strategy: core_1.MeasuringStrategy.Always,
            frequency: 10,
        },
    },
};
function DraggableContextProvider(_a) {
    var _b;
    var children = _a.children, setEntityGroupState = _a.setEntityGroupState;
    var _c = (0, react_1.useState)(undefined), activeCard = _c[0], setActiveCard = _c[1];
    var _d = (0, react_1.useState)(undefined), activeContainer = _d[0], setActiveContainer = _d[1];
    var apolloClient = (0, client_1.useApolloClient)();
    var updateWorkflowEntity = (0, useUpdateWorkflowEntity_1.useUpdateWorkflowEntity)();
    var pointerSensor = (0, core_1.useSensor)(core_1.PointerSensor, {
        activationConstraint: {
            distance: (0, components_1.grid)(0.5),
        },
    });
    var sensors = (0, core_1.useSensors)(pointerSensor);
    var onDragStart = (0, react_1.useCallback)(function (event) {
        setActiveCard(event.active);
    }, []);
    var onDragOver = (0, react_1.useCallback)(function (event) {
        var active = event.active, over = event.over;
        var activeId = active === null || active === void 0 ? void 0 : active.id;
        var overId = over === null || over === void 0 ? void 0 : over.id;
        setEntityGroupState(function (prev) {
            var _a;
            var oldGroupId = findGroupId(prev, activeId);
            var newGroupId = findGroupId(prev, overId);
            setActiveContainer(newGroupId);
            if ((0, lodash_1.isNil)(oldGroupId) || (0, lodash_1.isNil)(newGroupId)) {
                return prev;
            }
            if (oldGroupId === newGroupId) {
                return prev;
            }
            var oldGroup = prev[oldGroupId];
            var newGroup = prev[newGroupId];
            updateApolloCache({ apolloClient: apolloClient, entityId: String(activeId), oldGroup: oldGroup, newGroup: newGroup });
            var entity = oldGroup.entities.find(function (item) { return item.id === activeId; });
            if ((0, lodash_1.isNil)(entity)) {
                return prev;
            }
            return __assign(__assign({}, prev), (_a = {}, _a[oldGroupId] = __assign(__assign({}, oldGroup), { entities: oldGroup.entities.filter(function (item) { return item.id !== entity.id; }) }), _a[newGroupId] = __assign(__assign({}, newGroup), { entities: __spreadArray([entity], newGroup.entities.filter(function (item) { return item.id !== entity.id; }), true) }), _a));
        });
    }, [apolloClient, setEntityGroupState]);
    var onDragEnd = (0, react_1.useCallback)(function (event) {
        var over = event.over;
        var activeId = activeCard === null || activeCard === void 0 ? void 0 : activeCard.id;
        var overId = over === null || over === void 0 ? void 0 : over.id;
        setEntityGroupState(function (prev) {
            var _a, _b;
            var newGroupId = findGroupId(prev, overId);
            if ((0, lodash_1.isNil)(newGroupId)) {
                return prev;
            }
            var newGroup = prev[newGroupId];
            var entityUpdates = (_a = {},
                _a[newGroup.groupComponentId] = getGroupValue(newGroup.groupData),
                _a);
            updateWorkflowEntity(String(activeId), entityUpdates);
            // TODO remove this when sort mutation is ready
            if (!SORT_ENABLED) {
                return prev;
            }
            var activeIndex = newGroup.entities.findIndex(function (item) { return item.id === activeId; });
            var overIndex = newGroup.entities.findIndex(function (item) { return item.id === overId; });
            if (activeIndex === overIndex) {
                return prev;
            }
            return __assign(__assign({}, prev), (_b = {}, _b[newGroupId] = __assign(__assign({}, newGroup), { entities: (0, sortable_1.arrayMove)(newGroup.entities, activeIndex, overIndex) }), _b));
        });
        setActiveCard(undefined);
        setActiveContainer(undefined);
    }, [activeCard, updateWorkflowEntity, setEntityGroupState]);
    var onDragCancel = (0, react_1.useCallback)(function () {
        setActiveCard(undefined);
        setActiveContainer(undefined);
    }, []);
    var contextValue = (0, react_1.useMemo)(function () { return ({
        draggable: true,
        activeCard: activeCard,
        activeContainer: activeContainer,
    }); }, [activeCard, activeContainer]);
    return (react_1.default.createElement(core_1.DndContext, __assign({ sensors: sensors, onDragStart: onDragStart, onDragOver: onDragOver, onDragEnd: onDragEnd, onDragCancel: onDragCancel }, dndContextConfig),
        react_1.default.createElement(DraggableContext_1.DraggableContext.Provider, { value: contextValue }, children),
        react_1.default.createElement(core_1.DragOverlay, null, activeCard && react_1.default.createElement(Card_1.Card, __assign({}, (_b = activeCard === null || activeCard === void 0 ? void 0 : activeCard.data.current) === null || _b === void 0 ? void 0 : _b.cardProps)))));
}
function getGroupValue(groupData) {
    var _a, _b;
    var value = (_a = groupData.selection[0]) === null || _a === void 0 ? void 0 : _a.value;
    if (value === 'empty') {
        return [];
    }
    else if (((_b = groupData.filter) === null || _b === void 0 ? void 0 : _b.type) === 'RELATED_CARD') {
        return [{ id: value }];
    }
    else {
        return [value];
    }
}
function findGroupId(prev, id) {
    var entityGroupId = Object.keys(prev).find(function (key) {
        return prev[key].entities.some(function (item) { return item.id === id; });
    });
    var groupId = Object.keys(prev).find(function (key) { return key === id; });
    return entityGroupId || groupId;
}
function updateApolloCache(_a) {
    var _b;
    var _c;
    var apolloClient = _a.apolloClient, entityId = _a.entityId, oldGroup = _a.oldGroup, newGroup = _a.newGroup;
    var oldGroupCache = apolloClient.readQuery({
        query: queries_1.Queries.workflow.listWorkflowEntities,
        variables: oldGroup.entityVariables,
    });
    var newGroupCache = apolloClient.readQuery({
        query: queries_1.Queries.workflow.listWorkflowEntities,
        variables: newGroup.entityVariables,
    });
    try {
        var entity = (_c = oldGroupCache.list.entities) === null || _c === void 0 ? void 0 : _c.find(function (item) { return item.id === entityId; });
        if ((0, lodash_1.isNil)(entity)) {
            return;
        }
        var newGroupValue = getGroupValue(newGroup.groupData);
        var updates = (_b = {},
            _b[newGroup.groupComponentId] = newGroupValue,
            _b);
        var updatedEntity = __assign(__assign({}, entity), { fields: __assign(__assign({}, entity.fields), updates) });
        // remove the entity from the old group
        apolloClient.writeQuery({
            query: queries_1.Queries.workflow.listWorkflowEntities,
            variables: oldGroup.entityVariables,
            data: __assign(__assign({}, oldGroupCache), { list: __assign(__assign({}, oldGroupCache.list), { 
                    // totalResults: Math.max(0, oldGroupCache.list.totalResults - 1),
                    entities: __spreadArray([], oldGroupCache.list.entities.filter(function (item) { return item.id !== entityId; }), true) }) }),
        });
        // add the entity to the new group
        apolloClient.writeQuery({
            query: queries_1.Queries.workflow.listWorkflowEntities,
            variables: newGroup.entityVariables,
            data: __assign(__assign({}, newGroupCache), { list: __assign(__assign({}, newGroupCache.list), { 
                    // totalResults: Math.max(0, newGroupCache.list.totalResults + 1),
                    entities: __spreadArray([
                        updatedEntity
                    ], newGroupCache.list.entities.filter(function (item) { return item.id !== entityId; }), true) }) }),
        });
    }
    catch (error) {
        console.error(error);
    }
}
